import React from "react"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import LogoImage from "../../../images/logo.png"
import { breakpoints } from "../../../helpers/media"
import CustomRichText from "../../../components/custom-rich-text"
import { DEFAULT_GREY_TITLE } from '../../../helpers/colors'
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 30px;
  padding-bottom: 60px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 80vw;
  margin-right: 80px;
  & p {
    font-size: 18px;
    color: #606676;
    opacity: 1;
  }
  @media only screen and (max-width: ${breakpoints.lg}px) {
    width: 80vw;
    align-items: center;
    justify-content: center;
    margin-right: 0px;
  }
`

const Title = styled.h3`
  font-size: 38px;
  letter-spacing: 0px;
  color: ${DEFAULT_GREY_TITLE};
  text-shadow: 0px 2px 3px #0000001f;
  opacity: 1;
  margin-bottom: 20px;
`

const Logo = styled.img`
  height: 350px;
  width: 350px;
  @media only screen and (max-width: ${breakpoints.md}px) {
    display: none;
  }
`

const Contact = styled.a`
  align-self: flex-end;
  background: #9f5ca7 0% 0% no-repeat padding-box;
  border-radius: 41px;
  height: 45px;
  text-align: center;
  width: fit-content;
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  opacity: 1;
  text-decoration: none;
  & p {
    font-size: 20px;
    letter-spacing: 0px;
    color: #f7f4f4;
    text-shadow: 0px 2px 3px #0000001f;
    opacity: 0.9;
  }
`

const CompanyOffer = ({ title, text, contact, noImage }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{RichText.asText(title)}</Title>
        <CustomRichText richText={text} />
        {contact ? (
          <Contact href={`mailto:${RichText.asText(contact)}`}>
            <p>{RichText.asText(contact)}</p>
          </Contact>
        ) : (
          ""
        )}
      </TextWrapper>
      {!noImage ? <Logo src={LogoImage} alt="logo" /> : ""}
    </Wrapper>
  )
}

export default CompanyOffer
