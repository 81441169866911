import React from 'react'
import styled from 'styled-components'
import InfoRow from '../../../components/info-row'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
const CompanySolutions = ({ solutions }) => {
  return (
    <Wrapper>
      {solutions.map((solution, index) => (
        <InfoRow
          key={index}
          title={solution.solution_title}
          text={solution.solution_text}
          image={solution.solution_imageSharp.childImageSharp.fluid}
        />
      ))}
    </Wrapper>
  )
}

export default CompanySolutions
