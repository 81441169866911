import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import useLanguage from '../hooks/use-language'
import { getNode } from '../helpers/graph-helper'
import CompanyHero from '../views/company/company-hero'
import CompanyOffer from '../views/company/company-offer'
import CompanySolutions from '../views/company/company-solutions'

const Foretag = ({ data }) => {
  const language = useLanguage()
  let node = getNode(language.locale, data, 'allCompany_pages')
  return (
    <Layout>
      <SEO title='Företag' />
      <CompanyHero
        url={node.hero_background_image.url}
        title={node.hero_title}
      />
      <CompanyOffer
        title={node.what_we_offer_title}
        text={node.what_we_offer_text}
        contact={node.what_we_offer_contact}
      />
      <CompanySolutions solutions={node.solutions} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allCompany_pages {
        edges {
          node {
            _meta {
              lang
            }
            hero_background_image
            hero_background_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                 ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_title
            what_we_offer_title
            what_we_offer_text
            what_we_offer_contact
            solutions {
              solution_title
              solution_text
              solution_image
              solution_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                   ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Foretag
