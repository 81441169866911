import React from 'react'
import styled from 'styled-components'
import { DEFAULT_COLOR, DEFAULT_GREY, DEFAULT_GREY_TITLE } from '../../helpers/colors'
import { H4 } from '../common/fonts'
import { RichText } from 'prismic-reactjs'
import { breakpoints } from '../../helpers/media'
import useTranslation from '../../hooks/use-translation'
import ClickableImg from '../clickable-img'
import CustomRichText from '../custom-rich-text'
const Wrapper = styled.div(({ small }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 85vw;
  overflow: hidden;
  width: 1200px;
  min-height: 300px;
  margin: ${small ? '0px 0px 0px 0px' : '0px'};
  @media only screen and (max-width: ${breakpoints.md}px) {
    margin: 0px;
    margin-top: 50px;
    flex-direction: column;
  }
  position: relative;
`)

const ImgWrapper = styled.div(
  ({ maxHeight, small }) => `
  min-width: ${small ? maxHeight : '20vw'};
  overflow: hidden;
  max-height: ${maxHeight};
  max-width: ${small ? maxHeight : '90%'};
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 250px;
    max-height: 300px;
  }
`
)

const Divider = styled.div(({ small }) => `
  height: 150px;
  min-width: 2px;
  background-color: white;
  margin: ${small ? '20px' : '5px'};
  @media only screen and (max-width: ${breakpoints.md}px) {
    min-width: 150px;
    height: 2px;
  }
`)

const TextWrapper = styled.div(
  ({ maxWidth, small }) => `
  display: flex;
  color: ${DEFAULT_GREY};
  flex-direction: column;
  & p {
    font-size: ${small ? 14 : 20}px;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    max-width: ${maxWidth ? maxWidth : ''};
    padding: 5px;
    & p {
      font-size: 14px;
    }
  }

`
)

const ActionWrapper = styled.div(({ small }) => `
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: ${small ? '4px 8px 4px 8px' : '5px 15px 5px 15px'};
  border-radius: 3px;
  background-color: ${DEFAULT_COLOR};
  color: white;
  font-size: ${small ? '14px' : 'default'};
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 4px 1px #0000008d;
  }
  @media only screen and (max-width: ${breakpoints.md}px) {
    position: relative;
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`)
const InfoRow = ({
  small = false,
  image,
  title,
  text,
  maxHeight = '300px',
  maxWidth,
  action
}) => {
  const t = useTranslation()
  return (
    <Wrapper small={small}>
      {image && (
        <ImgWrapper maxHeight={maxHeight} small={small}>
          <ClickableImg fluid={image} imgStyle={{ objectFit: 'contain', maxHeight, maxWidth: maxHeight }} />
        </ImgWrapper>
      )}
      <Divider small={small} />
      <TextWrapper maxWidth={maxWidth} style={action ? { marginBottom: '40px' } : {}} small={small}>
        <H4 color={DEFAULT_GREY_TITLE} margin='0px' small={small}>
          {RichText.asText(title)}
        </H4>
        <CustomRichText richText={text} />
      </TextWrapper>
      {action && (
        <ActionWrapper onClick={action.handleClick} small={small}>
          {t(action.title)}
        </ActionWrapper>
      )}
    </Wrapper>
  )
}

export default InfoRow
